import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { Carousel, Card, Container, Row, Col, Button } from "react-bootstrap";
import CountUp from "react-countup";
import confetti from "canvas-confetti";
import { Link } from "react-router-dom";
import "./HomePage.css"; // Custom CSS for additional styling

const HomePage = () => {
  const [facilities, setFacilities] = useState([]);
  const achieversRef = useRef(null);

  useEffect(() => {
    // Fetch facilities from JSON file
    fetch("./facilities.json")
      .then((response) => response.json())
      .then((data) => setFacilities(data))
      .catch((error) => console.error("Error fetching facilities:", error));

    // Trigger confetti effect when the Achievers section is in view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (achieversRef.current) {
      observer.observe(achieversRef.current);
    }

    // Cleanup observer
    return () => {
      if (achieversRef.current) {
        observer.unobserve(achieversRef.current);
      }
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show three slides at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Carousel>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src="./imgs/jatHostelBuilding.jpg"
            alt="Jat Hostel Building Photo Front"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src="./imgs/jatHostelBuilding.jpg"
            alt="Jat Hostel Building Photo Side"
          />
        </Carousel.Item>
      </Carousel>

      {/* Our AIM Section */}
      <div className="aim-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">Our AIM</h2>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card className="aim-card p-4 shadow-sm">
                <Card.Body>
                  <Card.Text className="text-center lead">
                    Our mission is to provide quality accommodation, educational
                    support, and holistic development opportunities for students
                    from the Jat community, fostering an environment where they
                    can thrive academically, personally, and professionally.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Achievers Section */}
      <div className="achiever-section py-5" ref={achieversRef}>
        <Container className="text-center">
          <h2 className="my-5 text-white">Achievers</h2>
          <Row className="justify-content-center">
            <Col md={6}>
              <Card className="achiever-card shadow-sm p-4">
                <Card.Body>
                  <h3 className="text-center mb-4 congratulations-text">
                    Congratulations to
                  </h3>
                  <div className="text-center">
                    <CountUp
                      start={0}
                      end={84}
                      duration={6}
                      className="display-4"
                      separator=","
                    />
                  </div>
                  <p className="text-center text-muted mt-3">
                    Hostel residents selected for various positions in central
                    or state government.
                  </p>
                  <div className="text-center mt-4">
                    <Button
                      as={Link}
                      to="/selected-candidates"
                      variant="primary"
                    >
                      View All
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Facilities Slider Section */}
      <div className="facilities-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">Facilities</h2>
          <Slider {...settings}>
            {facilities.map((facility, index) => (
              <div key={index} className="facility-slide">
                <Card className="facility-card shadow-sm">
                  <Card.Img
                    variant="top"
                    src={facility.image}
                    alt={facility.text}
                    className="facility-image"
                  />
                  <Card.Body>
                    <Card.Text className="text-center">
                      {facility.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Slider>
        </Container>
      </div>

      {/* KARYA KARINI Section */}
      <div className="team-section py-5">
        <Container>
          <h2 className="text-center mb-5">KARYA KARINI</h2>
          <Row className="text-center">
            <Col md={3}>
              <Card className="team-card shadow-sm">
                <Card.Img
                  variant="top"
                  src="./imgs/karyekarini/mehram-ji-nangwadiya.jpg"
                />
                <Card.Body>
                  <Card.Title>Mehram Nangwadiya</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Chairperson
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="team-card shadow-sm">
                <Card.Img
                  variant="top"
                  src="./imgs/karyekarini/arjun-ram-ji-lomrod.jpg"
                />
                <Card.Body>
                  <Card.Title>Arjun Ram Lomrod</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Secretary
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="team-card shadow-sm">
                <Card.Img
                  variant="top"
                  src="./imgs/karyekarini/shankar-lal-ji-jakhar.jpg"
                />
                <Card.Body>
                  <Card.Title>Shankar Lal Jakhar</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Executive Chairman
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="team-card shadow-sm">
                <Card.Img
                  variant="top"
                  src="./imgs/karyekarini/kojharam-ji-jakhar.jpg"
                />
                <Card.Body>
                  <Card.Title>Kojaram Jakhar</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Treasurer
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
