import React, { useEffect, useState } from "react";
import { Table, Container, Form } from "react-bootstrap";
import PaginationComponent from "./PaginationComponent"; // Import the Pagination component
import ScrollGuidePopup from "./ScrollGuidePopup"; // Import the popup component
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import "./App.css"; // Ensure you import the CSS file for styles
import "./ContributorsPage.css";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const ContributorsPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [sortColumn, setSortColumn] = useState("amount"); // Default to sort by amount
  const [sortOrder, setSortOrder] = useState("desc"); // Default sort order
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(true); // Show popup initially

  useEffect(() => {
    fetch("/bhamasha.json")
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        // Sort data by amount after fetching
        const sortedData = [...data].sort((a, b) => {
          if (a.amount < b.amount) return sortOrder === "asc" ? -1 : 1;
          if (a.amount > b.amount) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
        setData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [sortOrder]);

  useEffect(() => {
    // Re-sort data when sortColumn or sortOrder changes
    const sortedData = [...data].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  }, [sortColumn, sortOrder]);

  const handleSort = (column) => {
    const newSortOrder =
      sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="page-container">
      <Container className="my-4">
        <h2 className="text-center bhamasha-heading">Bhamasha</h2>
        <p className="text-center lead bhamasha-subtext">
          We thank all our Bhamashas for entrusting us to make a positive change
          in the community.
        </p>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <span></span> {/* Empty span to align items */}
          <Form.Group controlId="itemsPerPageSelect" className="mb-0">
            <Form.Label className="mr-2">Entries per page:</Form.Label>
            <Form.Control
              as="select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block" }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </Form.Control>
          </Form.Group>
        </div>

        <div className="table-wrapper">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  className="srno-column"
                  onClick={() => handleSort("id")}
                  style={{ cursor: "pointer" }}
                >
                  SrNo
                  {sortColumn === "id" && (
                    <i
                      className={`fas ${
                        sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"
                      } ml-2`}
                    ></i>
                  )}
                </th>
                <th
                  className="name-column"
                  onClick={() => handleSort("name")}
                  style={{ cursor: "pointer" }}
                >
                  Name
                  {sortColumn === "name" && (
                    <i
                      className={`fas ${
                        sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"
                      } ml-2`}
                    ></i>
                  )}
                </th>
                <th
                  className="father-name-column"
                  onClick={() => handleSort("father_name")}
                  style={{ cursor: "pointer" }}
                >
                  Father's Name
                  {sortColumn === "father_name" && (
                    <i
                      className={`fas ${
                        sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"
                      } ml-2`}
                    ></i>
                  )}
                </th>
                <th
                  className="amount-column"
                  onClick={() => handleSort("amount")}
                  style={{ cursor: "pointer" }}
                >
                  Amount
                  {sortColumn === "amount" && (
                    <i
                      className={`fas ${
                        sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"
                      } ml-2`}
                    ></i>
                  )}
                </th>

                <th
                  className="address-column"
                  onClick={() => handleSort("address")}
                  style={{ cursor: "pointer" }}
                >
                  Address
                  {sortColumn === "address" && (
                    <i
                      className={`fas ${
                        sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"
                      } ml-2`}
                    ></i>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((contributor, index) => (
                <tr key={contributor.id}>
                  <td className="srno-column">
                    {index + 1 + indexOfFirstItem}
                  </td>
                  <td className="name-column">{contributor.name}</td>
                  <td className="father-name-column">
                    {contributor.father_name}
                  </td>
                  <td className="amount-column">
                    {formatCurrency(contributor.amount)}
                  </td>
                  <td className="address-column">{contributor.address}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
        {showPopup && <ScrollGuidePopup onClose={() => setShowPopup(false)} />}
        <p className="text-center lead">Last Updated: 31/03/2025</p>
      </Container>
    </div>
  );
};

export default ContributorsPage;
