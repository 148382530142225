import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Carousel,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import only the close icon
import "./GalleryPage.css"; // Import CSS

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [expandedDescription, setExpandedDescription] = useState(false);

  useEffect(() => {
    // Fetch gallery from JSON file
    fetch("./gallery.json")
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

  const handleClose = () => {
    setShow(false);
    setExpandedDescription(false);
  };

  const handleShow = (group, index) => {
    setSelectedImages(group);
    setActiveIndex(index);
    setShow(true);
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const toggleDescription = () => {
    setExpandedDescription((prev) => !prev);
  };

  return (
    <div className="page-container">
      <Container className="my-4">
        <h2 className="text-center">Gallery</h2>
        <Row>
          {images.map((group, groupIndex) => (
            <Col md={4} sm={6} xs={12} key={groupIndex} className="mb-4">
              <Card
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(group, 0)} // Show the first image in the group
              >
                <Card.Img
                  variant="top"
                  src={group[0].src}
                  alt={`Gallery Group ${groupIndex + 1}`}
                />
                <Card.Body>
                  <Card.Text className="text-center">
                    {group[0].description.length > 100 && !expandedDescription
                      ? `${group[0].description.substring(0, 100)}... `
                      : group[0].description}
                    {group[0].description.length > 100 && (
                      <Button
                        variant="link"
                        onClick={toggleDescription}
                        className="p-0"
                      >
                        {expandedDescription ? "Show Less" : "Read More"}
                      </Button>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Full Screen Modal for Image Carousel */}
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog modal-dialog-centered modal-fullscreen"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center position-relative">
            <Button
              variant="link"
              className="close-button position-absolute"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <div className="image-container">
              <Carousel
                activeIndex={activeIndex}
                onSelect={handleSelect}
                interval={null}
                className="w-100"
                touch={true} // Enable swipe on mobile
              >
                {selectedImages.map((image, index) => (
                  <Carousel.Item key={index}>
                    <div className="text-center">
                      <img
                        className="d-block img-fluid"
                        src={image.src}
                        alt={image.description}
                        style={{
                          maxHeight: "70vh",
                          objectFit: "contain",
                        }}
                      />
                      <div className="description-scrollable">
                        <p
                          className="mt-2 text-black"
                          style={{ fontSize: "1.1rem", lineHeight: "1.5" }}
                        >
                          {image.description}
                        </p>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default GalleryPage;
